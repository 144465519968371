import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  addToFavorites,
  searchVets,
  validateZipCode,
  vetZipCodeChanged,
  cleanMyVetsErrors,
  searchPrimaryVet,
} from '../../../actions/myVets';
import close from '../../../assets/close-button.svg';
import { validateNumbersOnly } from '../../../services/utils';
import { EXPLORE_PROVIDERS } from '../../../constants';
import ModalContainer from '../../common/ModalContainer';
import LoadingActionButtonMyPets
from '../../common/petCloud/LoadingActionButtonMyPets';
import { FoundVetItem } from './FoundVetItem';
import { MyPetsSearchInput } from './MyPetsSearchInput';
import { MyPetsVetManualAdd } from './MyPetsAddVetManuallyModal';
import './MyPetsAddVetModal.css';
import { ActionButton } from '../../common/petCloud/ActionButton';

const MyPetsAddVetModal = ({
  customVetShowCancelButton = false,
  displaySelectOnRows = false,
  hideZipcode = false,
  onClose = () => { },
  selectedVet = null,
  setAddVetModalOpen = () => { },
  onSelectedVet = () => { },
  primaryVet = false,
}) => {
  const store = useSelector(({ personalInformation, myVets }) => ({
    ...myVets,
    userZipCode: personalInformation.zipCode,
  }), shallowEqual);
  const dispatch = useDispatch();
  const { t } = useTranslation('myPets');
  const [zipCode, setZipCode] = useState(store.userZipCode);
  const [willChangeZipCode, setWillChangeZipCode] = useState(false);
  const [zipCodeError, setZipCodeError] = useState('');
  const [isManuallyModalOpen, setIsManuallyModalOpen] = useState(false);
  const previousZipCodeRef = useRef(zipCode);

  const {
    addVetError,
    favoriteVets,
    isEditingZipCode,
    isLoadingVets,
    isSavingVet,
    isValidatingZipCode,
    isValidZipCode,
    lastZipcodeLoaded,
    userZipCode,
    vetSaved,
    vetsFound,
  } = store;

  useEffect(() => {
    if (!isValidatingZipCode) {
      if (zipCodeError === '' && !isValidZipCode
        && !isEditingZipCode) {
        setZipCodeError(t('MyVets.addVetModal.errorZipCode'));
      }

      if (isValidZipCode && !isEditingZipCode) {
        setWillChangeZipCode(false);
      }
    }
  }, [t, isValidZipCode, isEditingZipCode, zipCodeError, isValidatingZipCode]);

  useEffect(() => {
    if (!zipCode || zipCode.length < 5 || lastZipcodeLoaded === zipCode) {
      return;
    }

    if (primaryVet) {
      dispatch(searchPrimaryVet({ zipCode }));
    } else {
      dispatch(searchVets({ zipCode }));
    }
  }, [dispatch, zipCode]);

  const favoriteVetIds =
    useMemo(() => new Set(
      favoriteVets.map((vet) => vet.PlaceId),
    ), [favoriteVets]);

  function selectItem(item) {
    onSelectedVet(item);
  }

  function switchToChangeZipCode() {
    setWillChangeZipCode(true);
  }

  function handleClose() {
    onClose();
    setAddVetModalOpen(false);
    dispatch(cleanMyVetsErrors());
    onSelectedVet(null);
    setWillChangeZipCode(false);
    setZipCode(userZipCode);
    setZipCodeError('');
  }

  function handleCancel() {
    if (willChangeZipCode) {
      setZipCode(previousZipCodeRef.current);
      setWillChangeZipCode(false);
      setZipCodeError('');
      return;
    }

    if (!isSavingVet || !isValidatingZipCode) {
      handleClose();
    }
  }

  function handleSaveChangeZipCode() {
    if (zipCode.length !== 5) {
      setZipCodeError(t('MyVets.addVetModal.errorDigits'));
      return;
    }

    dispatch(validateZipCode({ zipCode }));
    setZipCodeError('');
  }

  function handleSave() {
    if (!willChangeZipCode && selectedVet) {
      dispatch(addToFavorites({
        categoryId: 1,
        placeId: selectedVet.PlaceId,
        providerId: EXPLORE_PROVIDERS.google,
      }));
      return;
    }

    handleSaveChangeZipCode();
  }

  function handleOnKeyDown(event) {
    const { keyCode } = event;
    const ENTER_KEY = 13;

    if (keyCode === ENTER_KEY) {
      handleSaveChangeZipCode();
    }
  }

  useEffect(() => {
    if (vetSaved && selectedVet) {
      handleClose();
    }
  }, [vetSaved]);

  function renderFoundItem(item) {
    return (
      <FoundVetItem
        key={item.PlaceId || item.Id}
        added={favoriteVetIds.has(item.PlaceId)}
        addedLabel={t('MyVets.addVetModal.added')}
        address={item.Address}
        name={item.Title || item.Name}
        rightText={displaySelectOnRows ? t('MyVets.addVetModal.select') : ''}
        showDistance={false}
      />
    );
  }

  function handleOpenAddVetManuallyModal() {
    setIsManuallyModalOpen(true);
  }

  function handleCloseAddVetManuallyModal() {
    setIsManuallyModalOpen(false);
    setAddVetModalOpen(true);
  }

  function handleSuccesCustomVetModal() {
    setIsManuallyModalOpen(false);
    onClose();
  }

  const renderNotListedMessage = () => (
    <>
      <hr className="My-pets-add-vet-modal-not-listed-hr" />

      <button
        className="Like-span My-pets-add-vet-modal-not-listed"
        onClick={handleOpenAddVetManuallyModal}
        type="button"
      >
        {t('MyVets.addVetModal.notListed')}

        &nbsp;

        {t('MyVets.addVetModal.enterManually')}
      </button>
    </>
  );

  function handleChangeZipCode(event) {
    const { value } = event.target;
    const cleanText = value ? value.replace(/[()-\s]/g, '') : '';
    const onlyNumbersValue = validateNumbersOnly(cleanText)
      ? cleanText
      : cleanText.replace(/\D/g, '');
    setZipCode(onlyNumbersValue);

    if (onlyNumbersValue.length === 5) {
      setZipCodeError('');
      previousZipCodeRef.current = onlyNumbersValue;
    }

    dispatch(vetZipCodeChanged());
  }

  function handleEmptyItemsFiltered(query) {
    const action = primaryVet ? searchPrimaryVet : searchVets;

    dispatch(action({ query, zipCode }));
  }

  function getSubtitle() {
    if (willChangeZipCode) {
      return t('MyVets.addVetModal.zipCodeSubtitle');
    }
    if (selectedVet) {
      return t('MyVets.checkInfo');
    }
    return t('MyVets.subtitle');
  }

  function getButtonText() {
    if (willChangeZipCode) {
      return t('MyVets.addVetModal.save');
    }
    if (selectedVet) {
      return t('MyVets.addVetModal.add');
    }
    return t('MyVets.addVetModal.next');
  }

  const VetModalActions = () => (
    <div className="My-pets-add-vet-modal-actions">
      <LoadingActionButtonMyPets
        disabled={!(willChangeZipCode || selectedVet)}
        loading={isSavingVet || isValidatingZipCode}
        onClick={handleSave}
        text={getButtonText(t, willChangeZipCode, selectedVet)}
        type="button"
      />

      <ActionButton
        isSecondaryButton
        onClick={handleCancel}
        title={t('MyVets.addVetModal.cancel')}
      />
    </div>
  );

  function renderSelectedItem() {
    return (
      <>
        <div className="My-pets-add-vet-modal-selected-vet">
          <span className="My-pets-add-vet-modal-vet-name">
            {selectedVet.Title || selectedVet.Name}
          </span>

          <div className="My-pets-add-vet-modal-vet-address">
            <span className="My-pets-add-vet-modal-vet-address-location">
              {selectedVet.AddressLine1}
            </span>

            <span className="My-pets-add-vet-modal-vet-address-location">
              {selectedVet.AddressLine2}
            </span>
          </div>
        </div>

        <VetModalActions />
      </>
    );
  }

  return (
    <>
      <ModalContainer show>
        <div className="My-pets-add-vet-modal">
          <button
            className="My-pets-add-vet-modal-close"
            onClick={handleCancel}
            type="button"
          >
            <img alt="" src={close} />
          </button>

          <h2 className={hideZipcode ? 'My-pets-add-vet-modal-margin' : ''}>
            {t('MyVets.addAVet')}
          </h2>

          <p className="My-pets-add-vet-modal-subtitle">
            {getSubtitle()}
          </p>

          {willChangeZipCode ? (
            <>
              <span className="My-pets-add-vet-modal-search">
                {t('MyVets.addVetModal.zipCode')}
              </span>

              <div className="My-pets-add-vet-zip-code-input-container">
                <input
                  className={`My-pets-add-vet-zip-code-input${zipCodeError
                    ? '-error' : ''}`}
                  maxLength={5}
                  onChange={handleChangeZipCode}
                  onKeyDown={handleOnKeyDown}
                  placeholder={t('MyVets.addVetModal.searchNearLocation')}
                  value={zipCode}
                />

                <VetModalActions />
              </div>

              {zipCodeError ? (
                <span className="My-pets-add-vet-zip-code-error">
                  {zipCodeError}
                </span>
              )
                : null}
            </>
          ) : (
            <>
              {!selectedVet && !hideZipcode && (
                <div>
                  <span className="My-pets-add-vet-modal-search">
                    {t('MyVets.addVetModal.searchingNear')}
                  </span>

                  <span className="My-pets-add-vet-modal-zipcode">
                    {zipCode}
                  </span>

                  <span className="My-pets-separator">
                    |
                  </span>

                  <button
                    className="My-pets-add-vet-modal-change Like-span"
                    disabled={isLoadingVets}
                    onClick={switchToChangeZipCode}
                    type="button"
                  >
                    {t('MyVets.addVetModal.change')}
                  </button>
                </div>
              )}

              <MyPetsSearchInput
                emptyMessage={renderNotListedMessage()}
                hideInput={isManuallyModalOpen || willChangeZipCode}
                id="addVet"
                isLoadingVets={isLoadingVets}
                itemListRender={renderFoundItem}
                itemSelected={selectedVet}
                itemsFound={vetsFound}
                onSearch={handleEmptyItemsFiltered}
                onSelected={selectItem}
                placeholder={t('MyVets.addVetModal.searchByName')}
                renderSelectedItem={renderSelectedItem}
              />
            </>
          )}

          {addVetError ? (
            <span className="My-pets-add-vet-zip-code-error">
              {t('MyVets.addVetModal.errorAdding')}
            </span>
          ) : null}
        </div>
      </ModalContainer>

      <MyPetsVetManualAdd
        closeModal={handleCloseAddVetManuallyModal}
        handleSuccesCustomVetModal={handleSuccesCustomVetModal}
        onSavePrimaryVet={onSelectedVet}
        primaryVet={primaryVet}
        show={isManuallyModalOpen}
        showCancelButton={customVetShowCancelButton}
      />
    </>
  );
};

export { MyPetsAddVetModal };
