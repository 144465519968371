import React, { useCallback, useEffect, useRef } from 'react';
import { WEBVIEW_API_TOKEN } from '../../../services/constants';
import { validateExpirationToken } from '../../../services/utils';
import { useB2CInfo } from '../../../hooks';

/** iFrame with refresh getToken b2c functionality */
export const Iframe = ({ allowFullScreen = false, className, src, title }) => {
  const iFrameRef = useRef(null);
  const { getProxyToken } = useB2CInfo();

  const getToken = useCallback(async (forceRefresh) => {
    try {
      let token = localStorage.getItem(WEBVIEW_API_TOKEN);

      const isExpiredValidToken = validateExpirationToken(token);

      if (forceRefresh || isExpiredValidToken) {
        token = await getProxyToken();
      }

      iFrameRef.current.contentWindow.postMessage({
        apis: [
          {
            api: 'proxy',
            token,
          },
        ],
        requestType: 'token',
      });
    } catch (error) {
      iFrameRef.current.contentWindow.postMessage({
        error: error?.message || '',
        requestType: 'token',
      });
    }
  }, [getProxyToken]);

  const onMessage = useCallback(({ data }) => {
    if (!src) {
      return;
    }

    if (data?.id === 'policy') {
      getToken(data?.forceRefresh || false);
    }
  }, [src]);

  useEffect(() => {
    window.addEventListener('message', onMessage);

    return () => {
      window.removeEventListener('message', onMessage);
    };
  }, [onMessage]);

  return (
    <iframe
      ref={iFrameRef}
      allowFullScreen={allowFullScreen}
      className={className || ''}
      src={src}
      title={title}
    />
  );
};
