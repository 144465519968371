import { select } from 'redux-saga/effects';

function* getFeatureFlagByName(ffName) {
  const featureFlags = yield select(({ settings }) => settings.featureFlags);
  return !!(featureFlags?.flags || {})[ffName];
}

export function* useCustomerApiForSagas() {
  return yield getFeatureFlagByName('PetCloudCustomerApi');
}

export function* useSocialApiForSagas() {
  return yield getFeatureFlagByName('PetCloudSocialApi');
}

export function* useCustomerApiV1ForSagas() {
  const featureFlags = yield select(({ settings }) => settings.featureFlags);
  return !!featureFlags?.flags?.PetCloudCustomerApiV1;
}

export function* useCustomerInboxDocsApiV2ForSagas() {
  const featureFlags = yield select(({ settings }) => settings.featureFlags);
  return !!featureFlags?.flags?.PetCloudCustomerApiV2InboxAndDocs;
}

export function* useClaimApiV2ForSagas() {
  const featureFlags = yield select(({ settings }) => settings.featureFlags);
  return !!featureFlags?.flags?.PetCloudClaimsApiV2;
}

export function* useCustomerApiV2MyPetsForSagas() {
  const featureFlags = yield select(({ settings }) => settings.featureFlags);
  return !!featureFlags?.flags?.PetCloudCustomerApiV2MyPets;
}

export function* useClaimsApiV1ForSagas() {
  const featureFlags = yield select(({ settings }) => settings.featureFlags);
  return !!featureFlags?.flags?.PetCloudClaimsApiV1;
}

export function* useClaimsApiV2ForSagas() {
  const featureFlags = yield select(({ settings }) => settings.featureFlags);
  return !!featureFlags?.flags?.PetCloudClaimsApiV2;
}

export function* useCustomerApi150617V1ForSagas() {
  const featureFlags = yield select(({ settings }) => settings.featureFlags);
  return !!featureFlags?.flags?.PetCloudCustomer150617ApiV1;
}

export function* usePolicyApiV1ForSagas() {
  return yield getFeatureFlagByName('PetCloudPolicyApiV1');
}

export function* usePolicySummaryV1ForSagas() {
  return yield getFeatureFlagByName('PetCloudPolicyApiV1Summary');
}

export function* useClaimsAPIV2T155969ForSagas() {
  const featureFlags = yield select(({ settings }) => settings.featureFlags);
  return !!featureFlags?.flags?.PetCloudClaimsAPIV2T155969;
}

export function* usePolicyApiV1T153274ForSagas() {
  const featureFlags = yield select(({ settings }) => settings.featureFlags);
  return !!featureFlags?.flags?.PetCloudT153274;
}

export function* useCustomerApiV1T162824ForSagas() {
  const featureFlags = yield select(({ settings }) => settings.featureFlags);
  return !!featureFlags?.flags?.PetCloudCustomerApiV1T162824;
}

export function* usePolicyApiV1T153276ForSagas() {
  const featureFlags = yield select(({ settings }) => settings.featureFlags);
  return !!featureFlags?.flags?.PetCloudPolicyApiV1T153276;
}

export function* usePolicyApiV1T153269ForSagas() {
  return yield getFeatureFlagByName('PetCloudCoverageT153269');
}

export function* usePolicyApiV1T159611ForSagas() {
  return yield getFeatureFlagByName('PetCloudPolicyApiV1T159611');
}

export function* useClaimsAPIV2T159603ForSagas() {
  return yield getFeatureFlagByName('PetCloudClaimsv2T159603');
}

export function* useClaimsAPIV1T165578ForSagas() {
  return yield getFeatureFlagByName('PetCloudClaimV1T165578');
}

export function* usePetCloudClaimsApiV1T164407ForSagas() {
  return yield getFeatureFlagByName('PetCloudClaimsApiV1T164407');
}

export function* usePetCloudRNNotificationV1T159707ForSagas() {
  return yield getFeatureFlagByName('PetCloudRNNotificationv1T159707');
}

export function* useSocialV1T169153ForSagas() {
  return yield getFeatureFlagByName('PetCloudSocialV1T169153');
}

export function* usePetCloudCustomerV1T169318ForSagas() {
  return yield getFeatureFlagByName('PetCloudCustomerV1T169318');
}

export function* usePetCloudPolicyV1T172596ForSagas() {
  return yield getFeatureFlagByName('PetCloudPolicyV1T172596');
}
